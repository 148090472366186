<template>
  <component :is="currentStepType" />
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { defineAsyncComponent } from '@vue/runtime-core'
// Load layout components dynamically.
const requireContext = require.context('~/pages/configurator/steps', true, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent(() => // import each component dynamically
    import('@/pages/configurator/steps/' + component + '.vue')
  )
})
export default {
  name: 'Options',
  components,
  setup () {
    const store = useStore()
    const currentStepType = computed(() => {
      const currentStep = store.getters['configurator/currentStep']
      return currentStep.type
    })
    return {
      currentStepType
    }
  }
}
</script>
